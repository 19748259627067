:root {
    --pri-blue: #265BFF;
    --pri-darkblue: #002087;
    --pri-darkerblue: #000428;
    --pri-text: #384450;
    --pri-title: #0A2540;
    --pri-footer: #9193a8;
    --pri-black: #000000;
}

body {
    font-family: 'Montserrat', PingFangSC-Light, "Microsoft Yahei", Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgb(11, 12, 27);
    color: #222;
}

ol,
ul {
    padding-left: 1.25rem;
    margin-bottom: 0;
}

p {
    margin-bottom: 0;
}

/* HEADER */
.navbar {
    z-index: 9999999999;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.3s linear;
}

.navbar-collapse {
    justify-content: center;
}

.navbar-brand {
    width: 130px;
}

.navbar .navbar-brand img {
    width: 100%;
}

.nav-item {
    margin: 0 .5rem;
}

.nav-link {
    color: #ffffff;
    font-size: .85rem;
    font-weight: 500;
}


.nav-link:focus,
.nav-link:hover {
    color: var(--pri-blue);
}

.navbar-toggler:focus {
    box-shadow: none;
}

.fixed-top {
    background-color: #000000e6;
}

.fixed-top:hover {
    background-color: #0b0c1b;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #ffffff;
    background-color: var(--pri-blue);
}

.languageWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.languageWrap .dropdown button {
    border: none;
}

.languageWrap .dropdown button:hover i {
    color: var(--pri-blue);
}

.languageWrap .dropdown button i {
    color: #ffffff;
    font-size: 1.25rem;
}

.languageWrap .dropdown .dropdown-toggle::after {
    display: none;
}

.languageWrap .dropdown .dropdown-menu {
    box-shadow: 0 6px 24px rgb(0 0 0 / 8%);
    border: none;
    padding: 0.5rem 0;
    background: #ffffff;
}

.languageWrap .dropdown .dropdown-menu .dropdown-item {
    font-size: .8rem;
    font-weight: 500;
    line-height: 2;
    padding: 0.25rem 0.5rem;
    border-bottom: none;
}

.languageWrap .dropdown .dropdown-menu .dropdown-item:hover {
    color: #ffffff;
    background: var(--pri-blue);
}

.ctaWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.ctaWrap .dropdown {
    margin-right: .5rem;
}

.ctaWrap .dropdown button {
    border: none;
}

.ctaWrap .dropdown button:hover i {
    color: var(--pri-blue);
}

.ctaWrap .dropdown button i {
    color: #ffffff;
    font-size: 1.25rem;
}

.ctaWrap .dropdown .dropdown-toggle::after {
    display: none;
}

.ctaWrap .dropdown .dropdown-menu {
    box-shadow: 0 6px 24px rgb(0 0 0 / 8%);
    border: none;
}

.ctaWrap .dropdown .dropdown-menu .dropdown-item {
    font-size: .8rem;
    font-weight: 500;
    line-height: 2;
}

.navBtn {
    color: #ffffff;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0.5rem 1.5rem;
    border: none;
    display: inline-block;
    text-decoration: none;
    transition: 0.2s linear;
    background: var(--pri-blue);
    text-align: center;
}

.navBtn:hover {
    opacity: 1;
    transition: 0.2s linear;
    background-color: #0725ff;
    box-shadow: 0px 15px 30px -10px #265bff80;
}


.navbar-toggler {
    font-size: 1.25rem;
    border: none;
    transition: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: url(../img/icon_menu.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.offcanvas.offcanvas-end {
    width: 100%;
    border-left: none;
}

.offcanvas-header {
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    box-shadow: 0 0 30px rgb(0 0 0 / 5%);
    background-color: #0b0c1b;
}


.offcanvas-header .offcanvas-title {
    width: 115px;
    padding-top: var(--bs-navbar-brand-padding-y);
    padding-bottom: var(--bs-navbar-brand-padding-y);
}

.offcanvas-header .offcanvas-title img {
    width: 100%;
}

.offcanvas-header .btn-close {
    padding: 0.75rem 1rem;
    background-image: url(../img/icon_close.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    opacity: 1;
}

.blackBar {
    background-color: #000000e6;
}

@media (min-width: 992px) {
    .navbar-expand-lg .offcanvas .offcanvas-body {
        align-items: center;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        box-shadow: 0 6px 24px rgb(0 0 0 / 8%);
        border: none;
    }

    .navbar-nav .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
        border-radius: 0;
    }

    .nav-item .dropdown-menu .dropdown-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: .8rem;
        font-weight: 500;
        line-height: 2;
    }

    .nav-item .dropdown-menu .dropdown-item:focus,
    .nav-item .dropdown-menu .dropdown-item:hover {
        background-color: var(--pri-blue);
    }

    .nav-item .dropdown-menu .dropdown-item:focus i,
    .nav-item .dropdown-menu .dropdown-item:hover i {
        opacity: 1;
        transition: 0.1s linear;
    }

    .nav-item .dropdown-menu .dropdown-item i {
        color: #ffffff;
        opacity: 0;
        transition: 0.1s linear;
    }

    .languageWrap .dropdown {
        display: none;
    }

    .ctaWrap .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
        border-radius: 0;
    }
}

@media (max-width: 992px) {
    .navbar-brand {
        width: 115px;
    }

    .navbar .nav-item {
        margin-right: 0;
        margin-bottom: 0;
        border-bottom: 1px solid #07184c;
        margin: 0;
    }

    .navbar .nav-item .nav-link {
        padding: 1rem 0 1rem .75rem;
        border-radius: 0;
        font-weight: 800;
    }

    .navbar .nav-item .nav-link:focus,
    .navbar .nav-item .nav-link:hover,
    .navbar .nav-item .nav-link:active {
        border-radius: 0;
        color: var(--pri-blue);
    }

    .dropdown-menu {
        border-radius: 0;
        margin: 0;
        border: none;
        padding: 0;
        background-color: #f9f9f9;
        --bs-dropdown-spacer: 0;
        border-radius: 0;
        overflow: hidden;
    }

    .navbar-nav {
        margin-right: 0;
    }

    .navbar .nav-item .dropdown-toggle.show {
        color: var(--pri-blue);
    }

    .dropdown-menu .dropdown-item i {
        color: var(--pri-blue);
        margin-left: 0.25rem;
    }

    .dropdown-menu .dropdown-item {
        padding: 1rem 0 0.75rem 0.75rem;
        font-size: .8rem;
        background: #fff;
        color: var(--pri-text);
        border-bottom: 1px solid #ececec;
        font-weight: 500;
    }

    .dropdown-menu .dropdown-item:last-child {
        padding-bottom: 1rem;
    }

    .ctaWrap .dropdown {
        display: none;
    }

    .navBtn {
        width: 100%;
        margin-top: 5rem;
        padding: 0.75rem 1.5rem;
        transition: none;
        font-weight: 500;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    .offcanvas-body {
        background: rgb(11, 12, 27);
        background: linear-gradient(180deg, rgba(11, 12, 27, 1) 0%, rgba(5, 3, 52, 1) 100%);
    }


}

/* Hero */
.HeroWrap {
    height: 100vh;
    background: rgb(11, 12, 27);
    background: linear-gradient(180deg, rgba(11, 12, 27, 1) 0%, rgba(5, 3, 52, 1) 100%);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background: url("../img/img_hero_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.Herotagline h1 {
    font-size: 3.6rem;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.Herotagline p {
    font-size: 1.125rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 1.6rem;
    line-height: 1.8;
}

.mainBtn {
    background: var(--pri-blue);
    padding: 0.75rem 2rem;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 3px;
    font-weight: 500;
    display: inline-block;
    font-size: .9rem;
    transition: 0.2s linear;
}

.mainBtn:hover {
    background-color: #0725ff;
    box-shadow: 0px 15px 30px -10px #265bff80;
    transition: 0.2s linear;
}

.mainBtn:hover i {
    margin-left: .75rem;
    transition: 0.2s linear;
}

.mainBtn i {
    margin-left: .5rem;
    transition: 0.2s linear;
}


@media (max-width: 992px) {

    .HeroWrap {
        background-position: bottom;
    }

    .Herotagline {
        padding: 0 2rem;
    }

    .Herotagline h1 {
        font-size: 3.2rem;
    }
}

@media (max-width: 768px) {


    .Herotagline h1 {
        font-size: 3rem;
    }

    .Herotagline p {
        font-size: .85rem;
    }
}



/* Core */

.CoreWrap {
    height: 100vh;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 1, 45, 1) 100%);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.CoreContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.Coretagline h3 {
    font-size: 2.8rem;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.Coretagline p {
    font-size: 1rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 1.6rem;
    line-height: 1.8;
}

.CoreWrap img {
    width: 100%;
}


@media (max-width: 992px) {
    .CoreWrap {
        padding: 3rem 0;
        height: auto;
    }

    .Coretagline {
        padding: 0 2rem;
    }

    .Coretagline h3 {
        font-size: 2.2rem;
    }
}

@media (max-width: 768px) {
    .Coretagline p {
        font-size: .85rem;
    }
}

/* why */

.WhyWrap {
    height: 100vh;
    background: url("../img/img_why_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
}

.WhyContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.Whytagline {
    text-align: center;
}

.Whytagline h3 {
    font-size: 2.8rem;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.Whytagline p {
    font-size: 1rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 1.6rem;
    line-height: 1.8;
}

.techContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
    margin-top: 6rem;
}

.techContainer li {
    width: 32%;
    margin-right: 2%;
    margin-bottom: 5%;
}

.techContainer li:last-child {
    margin-right: 0;
}

.techContainer li:nth-child(3n) {
    margin-right: 0;
}

.techContainer li img {
    width: 12%;
    margin-bottom: 2.5rem;
}

.techContainer li h3 {
    font-size: 1rem;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.techContainer li p {
    font-size: .85rem;
    color: #ffffff;
    font-weight: 400;
    line-height: 1.8;
}

@media (max-width: 992px) {

    .WhyWrap {
        background-position: bottom;
    }

    .Whytagline {
        padding: 0 2rem;
    }

    .Whytagline h3 {
        font-size: 2.2rem;
    }
}

@media (max-width:768px) {
    .WhyWrap {
        height: auto;
        padding: 3rem 0;
        background-position: bottom;
        padding-bottom: 8rem;
    }


    .techContainer {
        flex-direction: column;
        align-items: center;
    }

    .techContainer li {
        width: 85%;
        margin: 0 1.5rem;
        margin-bottom: 4rem;
        text-align: center;
    }

    .techContainer li:last-child {
        margin-bottom: 0;
        margin-right: 1.5rem;
    }

    .techContainer li:nth-child(3n) {
        margin-right: 1.5rem;
    }

    .techContainer li img {
        width: 15%;
        margin-bottom: 2rem;
    }

    .techContainer li p {
        text-align: left;
    }
}

/* Product */

.productWrap {
    background-color: #f1f4ff;
    display: flex;
    align-items: center;
    padding: 5rem 0;
}

.productContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.producttagline {
    text-align: center;
}

.producttagline h3 {
    font-size: 2.8rem;
    color: var(--pri-title);
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.producttagline p {
    font-size: 1rem;
    color: var(--pri-text);
    font-weight: 400;
    margin-top: 1.6rem;
    line-height: 1.8;
}

.productInfoContainer {
    margin-top: 6rem;
}

.productInfoContainer .productCard {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 3rem;
    justify-content: center;
}

.productCard .cardWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.productInfoWrap {
    display: flex;
    flex-direction: row;
}

.productCard .productImg {
    width: 60px;
    border-radius: 18px;
    margin-right: 1rem;
    box-shadow: 0px 15px 20px -20px rgb(0 0 0 / 25%);
    overflow: hidden;
}

.productCard .productImg img {
    width: 100%;
}

.productCard .productInfo h3 {
    font-size: 1rem;
    color: var(--pri-text);
    letter-spacing: 0;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
}

.productCard .productInfo p {
    font-size: .6rem;
    text-transform: uppercase;
    color: var(--pri-blue);
    font-weight: 500;
    line-height: 1.8;
}

.productCard a {
    color: var(--pri-text);
    font-size: 1.5rem;
    transition: 0.2s linear;
}

.productCard a i {
    margin-left: .5rem;
    transition: 0.2s linear;
}

.productCard a:hover {
    color: var(--pri-blue);
    transition: 0.2s linear;
}

.productCard a:hover i {
    margin-left: .75rem;
    transition: 0.2s linear;
}

@media (max-width: 992px) {
    .producttagline {
        padding: 0 2rem;
    }

    .producttagline h3 {
        font-size: 2.2rem;
    }
}

@media (max-width:768px) {

    .productInfoContainer {
        margin-top: 3rem;
    }

    .producttagline p {
        font-size: .85rem;
    }

    .productCard .cardWrap {
        flex-direction: column;
        align-items: flex-start;
    }

    .productCard .productInfo p {
        white-space: nowrap;
        font-size: .5rem;
    }

    .productCard .productInfo h3 {
        font-size: .9rem;
    }

    .productCard .productImg {
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .productCard a i {
        margin-left: 0.25rem;
    }

    .productCard a:hover i {
        margin-left: 0.5rem;
    }
}


/* Clients */

.clientsWrap {
    background: #ffffff;
}

.clientsContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 6rem 0;
}

.clientsContainer>h3 {
    font-size: 2.8rem;
    color: var(--pri-title);
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
    text-align: center;
}

.clientsContainer>p {
    font-size: 1rem;
    color: var(--pri-text);
    font-weight: 400;
    margin-top: 1.6rem;
    line-height: 1.8;
    text-align: center;
}

.clientsLogo {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.clientsLogo li {
    width: 110px;
    margin: 1.5rem;
}

.clientsLogo li img {
    width: 100%;
}


@media (max-width: 768px) {
    .clientsLogo li {
        width: 100px;
        margin: 1.125rem;
    }
}

@media (max-width: 600px) {
    .clientsLogo li {
        width: 80px;
        margin: 1rem;
    }

    .clientsLogo {
        justify-content: space-between;
    }
}

.clientsTab {
    margin-top: 2rem;
}

.clientsTab .nav {
    justify-content: center;
}

.clientsTab .nav .nav-link {
    color: var(--pri-text);
    font-size: .85rem;
    font-weight: 500;
    background-color: #f1f1f1;
}
.clientsTab  .nav-link:focus, .clientsTab .nav-link:hover {
    background-color: var(--pri-blue);
    color: #ffffff;
}

.clientsTab .nav-pills .nav-link.active,
.clientsTab .nav-pills .show>.nav-link {
    color: #ffffff;
    background-color: var(--pri-blue);
}

@media (max-width: 992px) {
    .clientsContainer>h3 {
        width: 90%;
        font-size: 2.2rem;
    }

    .clientsLogo li {
        width: 120px;
        margin: 0.5rem .75rem;
    }
}

@media (max-width: 768px) {
    .clientsContainer>p {
        font-size: .85rem;
    }

    .clientsTab .nav .nav-link {
        font-size: .9rem;
    }

    .nav-item {
        margin: 0 0.25rem;
    }
}


.storyContainer .swiper {
    width: 100%;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    margin-top: 3rem;
    padding-bottom: 2rem;
}

.storyContainer .swiper-slide {
    background: #ffffff;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
    padding: 1.5rem;
    border-radius: 4px;
    text-align: left;
    min-height: 200px;
}


.storyContainer .swiper-slide img {
    width: 130px;
    margin-bottom: .75rem;
}

.storyContainer .swiper-slide h3 {
    font-size: 1rem;
    color: var(--pri-title);
    font-weight: 600;
    line-height: 1.8;

}

.storyContainer .swiper-slide p {
    font-size: .85rem;
    color: var(--pri-text);
    font-weight: 400;
    text-align: left;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.storyContainer .swiper-slide a {
    font-size: .75rem;
    color: var(--pri-blue);
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    margin-top: 1rem;
    display: inline-block;
}

.storyContainer .swiper-slide a:hover i {
    margin-left: .5rem;
    transition: 0.2s linear;
}

.storyContainer .swiper-slide a i {
    margin-left: .25rem;
    transition: 0.2s linear;
}

.swiper-button-next,
.swiper-button-prev {
    background: var(--pri-blue);
    color: #ffffff;
    padding: 0.5rem;
    overflow: hidden;
    width: 30px;
    height: 30px;
    top: calc(50% - 15px);
    margin-top: unset;
    border-radius: 50%;
    content: '';
    transition: 0.2s linear;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    opacity: .9;
    transition: 0.2s linear;
}

.swiper-button-next i,
.swiper-button-prev i {
    color: #ffffff;
}

.swiper-button-prev {
    left: 10px;
}

.swiper-button-next {
    right: 10px;
}

.swiper-button-prev:after {
    content: '';
}

.swiper-button-next:after {
    content: '';
}

.swiper-pagination-bullet {
    background: #bbbbbb;
}

.swiper-pagination-bullet-active {
    background: var(--pri-blue);
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    display: none;
}


/* Contact */
.contactWrap {
    background: rgb(38, 91, 255);
    background: linear-gradient(120deg, rgba(38, 91, 255, 1) 0%, rgba(0, 32, 135, 1) 100%);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 4rem 0;
}

.contactWrap.addImg {
    background: url(../img/img_intern_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.contacttagline h3 {
    font-size: 2em;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.contacttagline p {
    font-size: 1rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 1rem;
    line-height: 1.8;
}

.contactBtn {
    background: #ffffff;
    padding: 0.75rem 2rem;
    color: var(--pri-blue);
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    font-weight: 500;
    display: inline-block;
    font-size: .8rem;
    transition: 0.2s linear;
    text-align: center;
}

.contactBtn:hover i {
    margin-left: .75rem;
    transition: 0.2s linear;
}

.contactBtn i {
    margin-left: .5rem;
    transition: 0.2s linear;
}

@media (max-width: 992px) {
    .contacttagline {
        padding: 0 2rem;
    }
}

@media (max-width: 768px) {
    .contactBtn {
        padding: 0.75rem 1rem;
        font-size: .8rem;
        width: 100%;
        
    }
}

/* Press */
.pressWrap {
    background: #ffffff;

}

.pressContainer {
    padding: 6rem 0;
}

.pressContainer>h3 {
    font-size: 2.8rem;
    color: var(--pri-title);
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
    text-align: center;
    margin-bottom: 4rem;
}

.pressContainer>ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
}

.pressContainer>ul li {
    width: 32%;
    margin-right: 2%;
    box-shadow: 0px 15px 20px -20px rgb(0 0 0 / 15%);
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 2%;
}

.pressContainer>ul li:nth-child(3n) {
    margin-right: 0;
}

.pressContainer>ul li:last-child {
    margin-right: 0;
}

.pressContainer>ul li:hover img {
    opacity: .9;
    transition: 0.2s linear;
}

.pressContainer>ul li img {
    width: 100%;
    transition: 0.2s linear;
}

.pressContent {
    padding: 1rem;
}

.pressContent h3 {
    font-size: 1.125rem;
    color: var(--pri-text);
    font-weight: 500;
    line-height: 1.8;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pressContent p {
    font-size: .85rem;
    color: var(--pri-text);
    font-weight: 400;
    text-align: left;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pressContent a {
    font-size: .75rem;
    color: var(--pri-blue);
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    margin-top: 1rem;
    display: inline-block;
}

.pressContent a:hover i {
    margin-left: .5rem;
    transition: 0.2s linear;
}

.pressContent a i {
    margin-left: .25rem;
    transition: 0.2s linear;
}


@media (max-width: 992px) {
    .pressContainer>h3 {
        font-size: 2.2rem;
    }
}

@media (max-width: 768px) {
    .pressContainer>ul {
        align-items: center;
        flex-wrap: wrap;
    }

    .pressContainer>ul li {
        width: 49%;
        margin-bottom: 2%;
    }

    .pressContainer>ul li:nth-child(even) {
        margin-right: 0;
    }

    .pressContainer>ul li:nth-child(3n) {
        margin-right: 2%;
    }

    .pressContainer>ul li:nth-child(6n) {
        margin-right: 0;
    }

    .pressContainer>ul li:last-child {
        margin-bottom: 2%;
        margin-right: 0;
    }
}


@media (max-width: 600px) {
    .pressContainer>ul {
        flex-direction: column;
    }

    .pressContainer>ul li {
        width: 95%;
        margin-bottom: 5%;
        margin-right: unset;
    }

    .pressContainer>ul li:nth-child(3n) {
        margin-right: 0;
    }
}


/* News Center */
.newsWrap {
    background: #ffffff;

}

.newsContainer {
    padding: 6rem 0 16rem 0;
}

.newsContainer>h3 {
    font-size: 2.8rem;
    color: var(--pri-title);
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
    text-align: center;
    margin-bottom: 4rem;
}

.newsContainer ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
}

.newsContainer ul li {
    width: 32%;
    margin-right: 2%;
    margin-bottom: 2%;
    box-shadow: 0px 15px 20px -20px rgb(0 0 0 / 15%);
    border-radius: 4px;
    overflow: hidden;
    background: url(../img/img_press_default.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.newsContainer ul li:nth-child(3n) {
    margin-right: 0;
}

.newsContent {
    padding: 1.5rem;
}

.newsContent h3 {
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: 700;
    line-height: 1;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.newsContent p {
    font-size: .7rem;
    color: #ffffff;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0.5rem;
}

.newsContent a {
    font-size: .75rem;
    color: #ffffff;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    margin-top: 1rem;
    display: inline-block;
}

.newsContent a:hover {
    color: var(--pri-blue);
    transition: 0.2s linear;
}

.newsContent a:hover i {
    margin-left: .5rem;
    transition: 0.2s linear;
}

.newsContent a i {
    margin-left: .25rem;
    transition: 0.2s linear;
}

@media (max-width: 992px) {
    .newsContainer>h3 {
        font-size: 2.2rem;
    }
}

@media (max-width: 768px) {
    .newsContainer ul {
        flex-wrap: wrap;
    }

    .newsContainer ul li {
        width: 49%;
    }

    .newsContainer ul li:nth-child(even) {
        margin-right: 0;
    }

    .newsContainer ul li:nth-child(3n) {
        margin-right: 2%;
    }

    .newsContainer ul li:nth-child(6n) {
        margin-right: 0;
    }
}

@media (max-width: 600px) {

    .newsContainer ul {
        justify-content: center;
    }


    .newsContainer ul li {
        width: 95%;
        margin-right: 0;
        margin-bottom: 5%;
    }

    .newsContainer ul li:nth-child(3n) {
        margin-right: 0;
    }

    .newsContainer ul li:last-child {
        margin-bottom: 0;
    }
}

/* Career Banner */
.careerBannerWrap {
    margin-top: -8rem;
}

.careerBannerContainer {
    background: var(--pri-blue);
    padding: 3rem 2rem;
}

.careerBannertagline h3 {
    font-size: 2.2rem;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.careerBannertagline p {
    font-size: 1.6rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 1rem;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.careerBannertagline a {
    background: #ffffff;
    padding: 0.75rem 2.5rem;
    color: var(--pri-blue);
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 3px;
    font-weight: 500;
    display: inline-block;
    font-size: .9rem;
    transition: 0.2s linear;
}

.careerBannertagline a:hover i {
    margin-left: .5rem;
    transition: 0.2s linear;
}

.careerBannertagline a i {
    margin-left: .25rem;
    transition: 0.2s linear;
}

/* Footer */

.footerTop {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 5rem;
}

.footerTop .footerLogo {
    width: 130px;
}

.footerTop .footerLogo img {
    width: 100%;
}

.socialList {
    display: flex;
    list-style: none;
}

.socialList li {
    margin-left: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: .7rem;
    font-weight: 600;
}

.socialList li a {
    color: var(--pri-footer);
    font-size: 1.25rem;
}

.socialList li a:hover {
    color: #ffffff;
}

.footerLink {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5rem;
    margin-bottom: 5rem;
    list-style: none;
}


.footerLink li h3 {
    text-transform: uppercase;
    color: #ffffff;
    font-size: .75rem;
    font-weight: 800;
    margin-bottom: 2rem;
}

.footerLink li a {
    display: block;
    color: var(--pri-footer);
    font-size: .75rem;
    line-height: 2;
    text-decoration: none;
    margin-bottom: 0.25rem;
}

.footerLink li a:last-child {
    margin-bottom: 0;
}

.footerLink li a:hover {
    color: #ffffff;
}

.footerBottom {
    border-top: 1px solid var(--pri-blue);
    padding-top: 2rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.footerBottom p {
    color: var(--pri-footer);
    font-size: .75rem;
    line-height: 2;
}

.footerBottom .terms a {
    color: var(--pri-footer);
    font-size: .75rem;
    line-height: 2;
    text-decoration: none;
    margin-left: .5rem;
}

.footerBottom .terms a:hover {
    color: #ffffff;
}

@media (max-width: 992px) {

    .footerTop,
    .footerLink {
        padding: 0 2rem;
    }

    .footerBottom {
        padding: 2rem 2rem 5rem 2rem;

    }
}

@media (max-width: 768px) {

    .footerLink {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .footerLink li {
        width: 33.333333%;
        margin-bottom: 2rem;
    }
}

@media (max-width: 600px) {

    .footerTop,
    .footerLink {
        padding: 0 1rem;
    }

    .footerBottom {
        padding: 2rem 1rem 5rem 1rem;

    }

    .footerLink li {
        width: 49%;
        margin-bottom: 2rem;
        margin-right: 2%;
    }

    .footerLink li:nth-child(even) {

        margin-right: 0;
    }

    .footerBottom {
        flex-direction: column;
    }
}



/* Solutions */

/* Hero */
.solutionHeroWrap {
    height: 100vh;
    background: rgb(11, 12, 27);
    background: linear-gradient(180deg, rgba(11, 12, 27, 1) 0%, rgba(5, 3, 52, 1) 100%);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background: url("../img/img_solution_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.solutionHerotagline h1 {
    font-size: 3.6rem;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.solutionHerotagline p {
    font-size: 1.125rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 1.6rem;
    line-height: 1.8;
}

@media (max-width: 992px) {

    .solutionHeroWrap {
        background-position: bottom;
    }

    .solutionHerotagline {
        padding: 0 2rem;
    }

    .solutionHerotagline h1 {
        font-size: 3.2rem;
    }
}

@media (max-width: 768px) {


    .solutionHerotagline h1 {
        font-size: 2.6rem;
    }

    .solutionHerotagline p {
        font-size: .85rem;
    }
}

/* Our Solutions */

.solutionWrap {
    background: #ffffff;
    padding: 6rem 0;
}

.solutionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.solutiontagline h3 {
    font-size: 2.8rem;
    color: var(--pri-title);
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.solutiontagline p {
    font-size: 1rem;
    color: var(--pri-text);
    font-weight: 400;
    margin-top: 1.6rem;
    line-height: 1.8;
}

.darkColor h3 {
    color: var(--pri-blue) !important;
    min-height: 40px;
}

.darkColor p {
    color: var(--pri-text) !important;
    font-weight: 400 !important;
}

@media (max-width: 768px) {

    .solutiontagline {
        padding: 0 2rem;
    }

    .solutiontagline h3 {
        font-size: 2.2rem;
    }

    .solutiontagline p {
        font-size: .85rem;
    }

    .darkColor h3 {
        min-height: unset;
    }
}


/* Partners */
.partnersWrap {
    background: #ffffff;
}

.partnersContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
    padding: 6rem 0;
}

.partnersContainer>h3 {
    font-size: 2.8rem;
    color: var(--pri-title);
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}


.partnersLogo {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.partnersLogo li {
    width: 110px;
    margin: 1.5rem;
}

.partnersLogo li img {
    width: 100%;
}


@media (max-width: 768px) {

    .partnersContainer>h3 {
        font-size: 2.2rem;
    }

    .partnersContainer {
        padding: 0 1rem;
    }



    .partnersLogo li {
        width: 100px;
        margin: 1.125rem;
    }
}

@media (max-width: 600px) {

    .partnersLogo li {
        width: 80px;
        margin: 1rem;
    }

    .partnersLogo {
        justify-content: space-between;
    }
}


/* About Us */

/* Hero */
.aboutHeroWrap {
    height: 100vh;
    background: rgb(11, 12, 27);
    background: linear-gradient(180deg, rgba(11, 12, 27, 1) 0%, rgba(5, 3, 52, 1) 100%);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background: url("../img/img_about_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.aboutHerotagline h1 {
    font-size: 2.6rem;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.aboutHerotagline p {
    font-size: 1.125rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 1.6rem;
    line-height: 1.8;
}

@media (max-width: 992px) {

    .aboutHeroWrap {
        background-position: bottom;
    }

    .aboutHerotagline {
        padding: 0 2rem;
    }

    .aboutHerotagline h1 {
        font-size: 3.2rem;
    }
}

@media (max-width: 768px) {


    .aboutHerotagline h1 {
        font-size: 2.6rem;
    }

    .aboutHerotagline p {
        font-size: .85rem;
    }
}

/* Mission */
.missionWrap {
    background: #ffffff;
    padding: 7rem 0;
    display: flex;
    align-items: center;
}

.missionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.missionImg img {
    width: 100%;
}

.missiontagline h3 {
    font-size: 2.2rem;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
    color: var(--pri-title);
}

.missiontagline p {
    font-size: 1rem;
    color: var(--pri-text);
    font-weight: 400;
    margin-top: 1.6rem;
    line-height: 1.8;
}

.moreText {
    background-color: var(--pri-blue);
    color: #ffffff;
    font-size: 1rem;
    bottom: 0;
    width: 300px;
    padding: 0.75rem;
    right: -20px;
}

.milestoneWrap {
    background: #F8FAFF;
    padding: 6rem 0;
}

.milestoneContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.milestonetagline h3 {
    text-align: center;
}

.milestonetagline h3 {
    font-size: 2.8rem;
    color: var(--pri-title);
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.milestone {
    margin: 3rem 0;
    position: relative;
}

.milestone::before {
    content: "";
    width: 3%;
    height: 100%;
    background: rgb(248, 250, 255);
    background: linear-gradient(90deg, rgba(248, 250, 255, 1) 0%, rgba(248, 250, 255, 0) 100%);
    top: 0;
    left: 0;
    z-index: 9999;
    position: absolute;
}

.milestone::after {
    content: "";
    width: 3%;
    height: 100%;
    background: rgb(248, 250, 255);
    background: linear-gradient(270deg, rgba(248, 250, 255, 1) 0%, rgba(248, 250, 255, 0) 100%);
    top: 0;
    right: 0;
    z-index: 9999;
    position: absolute;
}

.milestoneSwiper {
    padding: 1.5rem;
}

.milestoneSwiper::after {
    content: "";
    width: 100%;
    height: 1px;
    background: var(--pri-blue);
    position: absolute;
    opacity: .5;
    top: 15px;
}

.milestoneSwiper .swiper-slide {
    position: relative;
}

.milestoneSwiper .swiper-slide::after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--pri-blue);
    position: absolute;
    top: -12.5px;
}

.milestoneSwiper .swiper-slide .mileDate {
    font-size: .75rem;
    color: var(--pri-blue);
    font-weight: 700;
    line-height: 1;
    margin: .75rem 0;
}

.milestoneSwiper .swiper-slide h3 {
    font-size: .9rem;
    color: var(--pri-title);
    font-weight: 700;
    line-height: 1;
    margin-bottom: .5rem;
}

.milestoneSwiper .swiper-slide p {
    font-size: .8rem;
    color: var(--pri-text);
    font-weight: 300;
    line-height: 1.8;
}

.officeMapWrap {
    padding: 6rem 0 16rem 0;
    display: flex;
    align-items: center;
    background: rgb(11, 12, 27);
    background: linear-gradient(0deg, rgba(11, 12, 27, 1) 0%, rgba(0, 1, 45, 1) 100%);
}

.officeMapContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.officeMaptagline {
    text-align: center;
}

.officeMaptagline h3 {
    font-size: 2.8rem;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.officeMaptagline p {
    font-size: 1rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 1.6rem;
    line-height: 1.8;
}

.officeInfoWrap {
    margin-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.officeInfoWrap .officeInfo {
    list-style: none;
}

.officeInfoWrap .officeInfo li {
    margin-bottom: 2.5rem;
}

.officeInfoWrap .officeInfo li h3 {
    font-size: 1rem;
    color: #ffffff;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 1rem;
}

.officeInfoWrap .officeInfo li p {
    font-size: .85rem;
    color: #ffffff;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: .5rem;
}

.officeInfoWrap .officeInfo li p i {
    color: var(--pri-blue);
}


.officeImg {
    padding: 1rem;
}

.officeImg img {
    width: 100%;
}

@media (max-width: 768px) {
    .missionWrap {
        padding: 5rem 0;
    }

    .missionContainer {
        padding: 0 2rem;
    }

    .missiontagline h3 {
        margin-top: 2rem;
    }

    .missiontagline p {
        margin-bottom: 2rem;
    }

    .milestonetagline h3 {
        font-size: 2.2rem;
    }

    .milestonetagline {
        padding: 0 2rem;
    }

    .officeMapContainer {
        padding: 0 2rem;
    }
}

@media (max-width: 600px) {
    .milestoneSwiper .swiper-slide {
        margin: 0 0.75rem;
    }

    .milestoneSwiper .swiper-slide p {
        width: 85%;
    }
}

/* PRESS  */

.fixPos {
    padding-top: 9rem;
}

.pressContainer.fixPos>h3 {
    text-align: left;
}

.newsContainer.fixPos>h3 {
    text-align: left;
}

@media (max-width: 768px) {
    .fixPos {
        padding-top: 8rem;
    }

    .pressContainer.fixPos>h3 {
        padding: 0 1.5rem;
    }

    .newsContainer.fixPos>h3 {
        padding: 0 1.5rem;
    }
}

/* Pagination */
.paginationContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 2rem;
    list-style: none;
}

.paginationContainer li {
    font-size: .9rem;
    line-height: 1;
    text-align: center;
    color: var(--pri-text);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.75rem;
}


.paginationContainer li.active {
    background-color: var(--pri-blue);
    border-radius: 6px;
    color: #ffffff;
    padding: 0.5rem 1rem;
}

.paginationContainer a {
    text-decoration: none;
    color: var(--pri-text);
}

.paginationContainer li a:hover {
    color: var(--pri-blue);
}

/* Career */

/* Hero */
.careerHeroWrap {
    height: 100vh;
    background: rgb(11, 12, 27);
    background: linear-gradient(180deg, rgba(11, 12, 27, 1) 0%, rgba(5, 3, 52, 1) 100%);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background: url("../img/img_careers_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.careerHerotagline h1 {
    font-size: 3.2rem;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.careerHerotagline p {
    font-size: 1.125rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 1.6rem;
    line-height: 1.8;
}

@media (max-width: 992px) {

    .careerHeroWrap {
        background-position: bottom;
    }

    .careerHerotagline {
        padding: 0 2rem;
    }

    .careerHerotagline h1 {
        font-size: 2.6rem;
    }
}

@media (max-width: 768px) {


    .careerHerotagline h1 {
        font-size: 2.6rem;
    }

    .careerHerotagline p {
        font-size: .85rem;
    }
}

.openWrap {
    background: #ffffff;
    padding: 0 0 8rem 0;
}

.openContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.opentagline {
    text-align: center;
    margin: 3rem 0;
}

.opentagline h3 {
    font-size: 2.8rem;
    color: var(--pri-title);
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.openList {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.openList a {
    position: relative;
    margin-right: 2%;
    overflow: hidden;
    border-radius: 6px;
    transition: 0.2s linear;
}

.openList a:hover img {
    transform: scale(1.1);
    opacity: .9;
    transition: 0.2s linear;
}

.openList a h3 {
    font-size: 1.6rem;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
    text-align: left;
    position: absolute;
    bottom: 15px;
    left: 15px;
    margin-bottom: 0;
    z-index: 99999;
}

.openList a:last-child {
    margin-right: 0;
}

.openList a img {
    width: 100%;
    transition: 0.2s linear;
}

@media (max-width: 1200px) {
    .openList a h3 {
        font-size: 1.35rem;
    }
}

@media (max-width: 992px) {
    .openList a h3 {
        font-size: 1.125rem;
        letter-spacing: -1px;
        bottom: 10px;
        left: 10px;
    }
}

@media (max-width: 768px) {
    .opentagline h3 {
        font-size: 2.2rem;

    }

    .openList a h3 {
        font-size: .9rem;
        letter-spacing: -1px;
        bottom: 10px;
        left: 10px;
    }


}

@media (max-width: 600px) {
    .openList {
        flex-wrap: wrap;
    }

    .openList a {
        width: 48.5%;
        margin-bottom: 3%;
        margin-right: 3%;
    }

    .openList a:nth-child(even) {
        margin-right: 0;
    }

    .openList a h3 {
        font-size: 1.25rem;
        letter-spacing: -1px;
    }
}


/* Terms */

/* Hero */
.termsHeroWrap {
    height: 50vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background: url(../img/img_terms_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}


.termsHerotagline h1 {
    font-size: 2.8rem;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.termsHerotagline p {
    font-size: 1.125rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 1.6rem;
    line-height: 1.8;
}



.termsWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 4rem 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(11, 12, 27, 1) 100%);
}

.termsContainer {
    margin-top: -6rem;

}

.termsContent {
    background: #ffffff;
    padding: 6rem;

}


.termsContent h3 {
    font-size: 1rem;
    color: var(--pri-title);
    font-weight: 600;
    margin-bottom: 1.25rem;
    text-transform: uppercase;
}


.termsContent p {
    font-size: .85rem;
    color: var(--pri-text);
    font-weight: 400;
    margin-bottom: 1rem;
    line-height: 1.8;
}

.termsContent ul {
    list-style-type: disc;
    padding: 0 0 0 20px;
    list-style-position: outside;
    font-size: .85rem;
    color: var(--pri-text);
    font-weight: 400;
    line-height: 2;
    margin-bottom: 1rem;
}

.termsContent ol {
    padding: 0 0 0 20px;
    list-style-position: outside;
    font-size: .85rem;
    color: var(--pri-text);
    font-weight: 400;
    line-height: 2;
    margin-bottom: 1rem;
}

.termsContent ul>ul,
.termsContent ul>li>ul {
    list-style-type: circle;
}

.termsContent ul>ul>ul,
.termsContent ul>li>ul>li>ul {
    list-style-type: square;
}


@media (max-width: 992px) {

    .termsHerotagline {
        padding: 0 2rem;
    }

    .termsHerotagline h1 {
        font-size: 2.6rem;
    }
}

@media (max-width: 768px) {


    .termsHerotagline h1 {
        font-size: 2.2rem;
    }

    .termsContainer {
        margin-top: -8rem;
        display: flex;
        justify-content: center;
    }

    .termsContent {
        padding: 3rem 2rem;
    }

}


/* Form */
.formWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 4rem 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(11, 12, 27, 1) 100%);
}

.formContainer {
    margin-top: -6rem;

}

.formContent {
    background: #ffffff;
}

.formleft {
    padding: 4rem;
    background: #265bff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url(../img/img_form_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

.formright {
    padding: 4rem;
}

.formleft>h3 {
    font-size: 1.125rem;
    color: #ffffff;
    margin-bottom: 2.5rem;
    line-height: 1.6;
}

.phoneContact {
    padding: 1.5rem;
    background: #ffffff;
    text-align: center;
}

.phoneContact img {
    height: 1rem;
    margin-right: .25rem;
}

.phoneContact h3 {
    font-size: 1.8rem;
    color: var(--pri-title);
    margin-bottom: 0;
    line-height: 2;
    font-weight: 800;
}

.phoneContact p {
    font-size: .85rem;
    color: var(--pri-title);
    font-weight: 400;
    line-height: 1;
}

.formright p {
    font-size: .8rem;
    font-weight: 500;
    line-height: 2;
    color: var(--pri-text);
}

.formright p a {
    color: var(--pri-blue);
}

.formright p a:hover {
    color: #0725ff;
}

.form-control {
    border-radius: 4px;
}

.formBtn {
    padding: .75rem 1.5rem;
    color: #ffffff;
    background: var(--pri-blue);
    border: none;
    text-transform: uppercase;
    width: 100%;
    letter-spacing: 3px;
    font-weight: 500;
    transition: 0.2s linear;
    margin-top: 4rem;
}

.formBtn:hover {
    background-color: #0725ff;
    box-shadow: 0px 15px 30px -10px #265bff80;
    transition: 0.2s linear;
}


.form-control:focus {
    border-color: var(--pri-blue);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(38 91 255 / 5%);
}


.form-floating>.form-control:not(:placeholder-shown)~label {
    opacity: 1;
    transform: scale(.75) translateY(-0.5rem) translateX(.25rem);
    font-weight: 500;
    color: var(--pri-footer);
}

.form-floating>.form-control:focus~label {
    opacity: 1;
    transform: scale(.75) translateY(-0.5rem) translateX(.25rem);
    color: var(--pri-blue);
    font-weight: 500;
}

.form-floating>label {
    padding: 1rem 1.5rem;
}


@media (max-width: 992px) {


    .formleft {
        padding: 1.5rem;
    }

    .formleft>h3 {
        margin-bottom: 2rem;
    }

    .formright {
        padding: 1.5rem;
    }

}

@media (max-width: 768px) {

    .formContainer {
        margin-top: -8rem;
        display: flex;
        justify-content: center;
    }

    .formright .formBtn {
        margin-bottom: 3rem;
    }

}


/* Job List */

/* Hero */
.jobsHeroWrap {
    height: 50vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background: url(../img/img_careers_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}


.jobsHerotagline h1 {
    font-size: 2.8rem;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.jobsHerotagline p {
    font-size: 1.8rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 1;
    line-height: 1.8;
}



.jobsWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 4rem 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(11, 12, 27, 1) 100%);
}

.jobsContainer {
    margin-top: -8rem;

}

.jobsContent {
    background: #ffffff;
    padding: 6rem;

}

.joblist {
    list-style: none;
}

.joblist li {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 2rem;
}

.joblist .departmentTitle {
    font-size: 1.8rem;
    color: var(--pri-title);
    font-weight: 800;
    line-height: 1;
    margin-bottom: 3rem;
}

.joblist li p {
    font-size: 1rem;
    color: var(--pri-text);
    font-weight: 400;
    margin-top: 1;
    line-height: 1.8;
}

.jobBtn {
    color: var(--pri-blue);
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0.5rem 1.5rem;
    display: inline-block;
    text-decoration: none;
    transition: 0.2s linear;
    background: #ffffff;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid var(--pri-blue);
}

.jobBtn:hover {
    background-color: var(--pri-blue);
    color: #ffffff;
    box-shadow: 0px 15px 30px -10px #265bff80;
    border: 1px solid var(--pri-blue);
    transition: 0.2s linear;
}

.jobBtn:hover i {
    margin-left: .75rem;
    transition: 0.2s linear;
}

.jobBtn i {
    margin-left: .5rem;
    transition: 0.2s linear;
}

.backBtn {
    text-decoration: none;
    color: var(--pri-text);
    font-weight: 500;
    font-size: 1rem;
    display: block;
}

.whiteText {
    color: #ffffff;
}

.backBtn:hover {
    color: var(--pri-blue);
}

.jobInfo h3 {
    font-size: 1.5rem;
    color: var(--pri-title);
    font-weight: 800;
    line-height: 1;
    margin-bottom: 1rem;
    letter-spacing: -1px;
}

.jobInfo p {
    font-size: .85rem;
    color: var(--pri-text);
    font-weight: 500;
    line-height: 2;
    margin-bottom: 3rem;
}

.jobInfo ul {
    margin-bottom: 2rem;
}

.jobInfo ul li{
    font-size: .85rem;
    color: var(--pri-text);
    font-weight: 500;
    line-height: 2;
    margin-bottom: .5rem;
}

.applyForm {
    padding: 2rem;
    background: #265bff08;
}

.requiredStyle {
    margin-bottom: 1rem;
    text-align: end;
    font-size: .85rem;
    font-weight: 500;
    color: #d22222;
}

.requiredStyle span{
    color: var(--pri-text);
}

@media (max-width: 992px) {

    .jobsHerotagline {
        padding: 0 2rem;
    }

    .jobsHerotagline h1 {
        font-size: 2.6rem;
    }

    .jobsContent {
        padding: 3rem 2rem;
    }
}

@media (max-width: 768px) {


    .jobsHerotagline h1 {
        font-size: 2.2rem;
    }

    .jobsContainer {
        margin-top: -8rem;
        display: flex;
        justify-content: center;
    }

    .jobsContent {
        padding: 3rem 2rem;
    }

    .joblist li {
        flex-direction: column;
        align-items:start;
        justify-content: space-between;
    }

    .jobBtn {
        margin-top: 2rem;
    }

}


/* Customer */

/* Hero */
.customerHeroWrap {
    height: 75vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background: url(../img/img_customer_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}


.customerHerotagline h1 {
    font-size: 3.6rem;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.customerHerotagline p {
    font-size: 1.125rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 1.6rem;
    line-height: 1.8;
}


.customerWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 4rem 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(11, 12, 27, 1) 100%);
}

.customerContainer {
    margin-top: -6rem;
}

.caseWrap {
    background: #f9f9f9;
}

.caseContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
    padding: 6rem 0;
}

.caseContent > h3 {
    font-size: 2.8rem;
    color: var(--pri-title);
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
    text-align: center;
}

.caselistContainer {
    list-style: none;
    display: flex;
    margin: 2rem 0;
    flex-direction: row;
    flex-wrap: wrap;
}

.caselistContainer li{
    background: #ffffff;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
    padding: 1.5rem;
    border-radius: 4px;
    text-align: left;
    width: 32%;
    margin-right: 2%;
    margin-bottom: 2%;
    overflow: hidden;
}

.caselistContainer li:nth-child(3n){
    margin-right: 0;
}

.caselistContainer li img{
    width: 130px;
    margin-bottom: 0.75rem;
}

.caselistContainer li h3{
    font-size: 1rem;
    color: var(--pri-title);
    font-weight: 600;
    line-height: 1.8;
}

.caselistContainer li p{
    font-size: .85rem;
    color: var(--pri-text);
    font-weight: 400;
    text-align: left;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.caselistContainer li a{
    font-size: .75rem;
    color: var(--pri-blue);
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    margin-top: 1rem;
    display: inline-block;
}

.caselistContainer li a:hover i {
    margin-left: .5rem;
    transition: 0.2s linear;
}

.caselistContainer li a i {
    margin-left: .25rem;
    transition: 0.2s linear;
}

@media (max-width: 992px) {

    .customerHerotagline {
        padding: 0 2rem;
    }

    .customerHerotagline h1 {
        font-size: 3.2rem;
    }
}

@media (max-width: 768px) {
    .customerHerotagline h1 {
        font-size: 2.6rem;
    }

    .customerHerotagline p {
        font-size: 1rem;
    }

    .caselistContainer li{
        width: 49%;
    }

    .caselistContainer li:nth-child(3n){
        margin-right: 2%;
    }

    .caselistContainer li:nth-child(even){
        margin-right: 0;
    }

    .caseContent > h3 {
        font-size: 2.2rem;
        color: var(--pri-title);
        letter-spacing: -2px;
        font-weight: 800;
        line-height: 1;
        text-align: center;
        padding: 0 1rem;
    }

}

@media (max-width: 600px) {

    .caseContainer {

        padding: 3rem 0;
    }

    .caselistContainer {
        justify-content: center;
    }

    .caselistContainer li{
        width: 95%;
        margin-right: 0;
        margin-bottom: 2rem;
    }
}


/* Story */

/* Hero */
.storyHeroWrap {
    height: 80vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background: url(../img/img_customer_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}


.storyHerotagline h1 {
    font-size: 3.2rem;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: 800;
    line-height: 1;
}

.storyHerotagline p {
    font-size: .85rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.8;
    text-transform: uppercase;
    letter-spacing: 2px;
}


.storyContentWrap {
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(11, 12, 27, 1) 100%);
    padding: 4rem 0;
}

.storyContentContainer {
    background: #ffffff;
    padding: 5rem;
}

.storyContent h3{
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 2;
    color: var(--pri-title);
    margin-bottom: 1rem;
}


.storyContent p{
    font-size: .9rem;
    font-weight: 400;
    line-height: 2;
    color: var(--pri-text);
    margin-bottom: 2rem;
}

@media (max-width: 992px) {

    .storyHerotagline {
        padding: 0 2rem;
    }

    .storyHerotagline h1 {
        font-size: 2.6rem;
    }

    .storyHerotagline p {
        font-size: .75rem;
    }

    .storyContentWrap {
        padding: 0;
    }

    .storyContentContainer {
        padding: 3rem 2rem; 
    }
}

@media (max-width: 768px) {

    .storyHeroWrap {
        height: 70vh;
    }

    .storyHerotagline h1 {
        font-size: 2.2rem;
    }

}